<template>
  <div />
</template>

<script>
export default {
  name: "ShareApp",
  mounted() {
    this.$root.$refs.loading.start();
    if (this.$route.query.code) {
      this.$router.push({
        name: "TopRegister",
        query: { code: this.$route.query.code }
      });
    } else {
      this.$router.push({ name: "TopRegister" });
    }
    this.$root.$refs.loading.finish();
  }
};
</script>
